import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { navigate } from "gatsby"
import Select from "react-select"

import "../../graphql/fragments"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Section, Container } from "../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
  const phoneNumberHandler = (val, setFieldValue) =>
    setFieldValue("phoneNumber", val)
  return (
    <Form className="row pt-4" id="thrForm">
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nama</label>
        <Field
          name="name"
          className="form-control px-0"
          placeholder="Nama Anda"
          type="text"
          id="customerName"
        />
        {touched.name && errors.name && (
          <div className="invalid-value d-block">{errors.name}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nomor Handphone</label>
        <NumberFormat
          name="phoneNumber"
          className="form-control px-0"
          placeholder="Nomor Handphone"
          allowEmptyFormatting
          prefix="+62"
          value={values.phoneNumber}
          onValueChange={(val) =>
            phoneNumberHandler(val.formattedValue, setFieldValue)
          }
          id="customerPhone"
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <div className="invalid-value d-block">{errors.phoneNumber}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Email</label>
        <Field
          name="email"
          className="form-control px-0"
          placeholder="Email Anda"
          type="text"
          id="customerEmail"
        />
        {touched.email && errors.email && (
          <div className="invalid-value d-block">{errors.email}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Produk Yang Dibeli</label>
        <Field
          name="noUnit"
          className="form-control px-0"
          placeholder="Produk Yang Dibeli"
          type="text"
          id="noUnit"
        />
        {touched.noUnit && errors.noUnit && (
          <div className="invalid-value d-block">{errors.noUnit}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Jumlah Voucher</label>
        <Select
          className="form-control px-0"
          classNamePrefix="1"
          options={[
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
          ]}
          defaultValue={{ value: "1", label: "1" }}
          id="jumlah"
          onChange={(e) => sessionStorage.setItem("jumlahVoucher", e.value)}
        />
        {touched.jumlah && errors.jumlah && (
          <div className="invalid-value d-block">{errors.jumlah}</div>
        )}
      </div>
      <div className="col-12 pt-5">
        <button className="btn btn-primary w-100 w-md-25">
          {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
        </button>
      </div>
    </Form>
  )
}

const ThrlebaranPage = (location) => {
  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    noUnit: "",
    jumlah: "",
  })
  // useEffect(() => {

  //     if (location.uri == '/thrlebaran'){
  //       return(
  //         document.getElementById('navbarDesktop').classList.add('d-none'),
  //         document.getElementById('navbarMobile').classList.add('d-none'),
  //         document.getElementsByClassName('animated')[0].classList.add('mt-0'),
  //         document.getElementsByClassName('footer-top')[0].classList.add('d-none'),
  //         document.getElementsByClassName('footer-bottom')[0].classList.add('d-none'),
  //         document.getElementsByClassName('navbar-nav-main')[0].classList.add('d-none'),
  //         document.querySelector('footer .red-ribbon .ribbon.black').style.background = "#fff",
  //         document.querySelector('footer .red-ribbon .ribbon.red').style.background = "linear-gradient(to right, #ab0203, #f00000) !important"
  //       )
  //     }

  // }, [])

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email, noUnit, jumlah }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        noUnit: formValues.noUnit || noUnit,
        jumlah: formValues.jumlah || jumlah,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      noUnit: Yup.string().required("Produk Yang Dibeli is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)

      var form = document.getElementById("thrForm")
      for (var i = 0; i < form.elements.length; i++) {
        var e = form.elements[i]
        //console.log(e);
        if (e.type === "button") continue
        var id = e.id
        var value = e.value
        sessionStorage.setItem(id, value)
      }
      // if users dont change Jumlah Voucher field, set default to 1
      if (!sessionStorage.getItem("jumlahVoucher")) {
        sessionStorage.setItem("jumlahVoucher", 1)
      }
      navigate("/thrlebaran/spin")
    },
  })(LeadForm)
  return (
    <Layout location={location} currentLocation={location.uri}>
      <SEO title="THR Lebaran" description="" />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      {/* <Section className="lineupThr"></Section> */}
      <Section className="mainThr bg-gray-50">
        <Container className="thrForm">
          <EnhancedLeadForm />
        </Container>
      </Section>
    </Layout>
  )
}
export default ThrlebaranPage
